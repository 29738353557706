import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from 'components/layout';

const error404 = ({ data }) => {
  const { allMenuYaml } = data;
  return (
    <Layout
      pageTitle={'404'}
      lang={'fr'}
      menuLinks={allMenuYaml.nodes[0].menuItems}
    >
      <div className="page404">
        <h1>Erreur 404 : La page n'existe pas...</h1>
        <h2 lang="en">Error 404 : Page not found...</h2>
      </div>
    </Layout>
  );
};

error404.propTypes = {
  data: PropTypes.object.isRequired,
};

export default error404;

export const query = graphql`
  query info404 {
    allMenuYaml(filter: { lang: { eq: "fr" } }) {
      nodes {
        menuItems {
          primaire {
            lien
            titre
          }
          produits {
            lien
            titre
          }
          services {
            lien
            titre
          }
          apropos {
            lien
            titre
          }
          accueil
          servicesTitre
          produitsTitre
          alert
        }
      }
    }
  }
`;
